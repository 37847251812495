import { TransactionAuthorizationActions } from './Authorization';
import { TransactionBillingData } from './Billing';
import { Car, CarCatalog } from './Car';
import { ChargingStation } from './ChargingStation';
import Consumption, { AbstractCurrentConsumption } from './Consumption';
import { OCPICdr } from './ocpi/OCPICdr';
import { OCPISession } from './ocpi/OCPISession';
import { ResolvedPricingModel } from './Pricing';
import { TransactionRefundData } from './Refund';
import { TableData } from './Table';
import { Tag } from './Tag';
import { User } from './User';

export interface Transaction extends TableData, AbstractCurrentConsumption, TransactionAuthorizationActions {
  id: number;
  timestamp: Date;
  chargeBox: ChargingStation;
  chargeBoxID: string;
  companyID: string;
  siteID: string;
  siteAreaID: string;
  userID: string;
  connectorId: number;
  meterStart: number;
  issuer: boolean;
  departureTime?: Date;
  initialStateOfChargePercent?: number;
  targetStateOfChargePercent?: number;
  carOdometer?: number;
  planningID?: string;
  currentTotalInactivitySecs: number;
  currentInactivityStatus: InactivityStatus;
  currentTotalDurationSecs: number;
  stateOfCharge: number;
  currentStateOfCharge: number;
  user: User;
  tagID: string;
  tag?: Tag;
  carID?: string;
  car?: Car;
  carCatalogID?: number;
  carCatalog?: CarCatalog;
  status: string;
  price: number;
  priceUnit: string;
  pricingModel?: ResolvedPricingModel;
  stop: {
    user: User;
    tagID: string;
    timestamp: Date;
    meterStop: number;
    totalConsumptionWh: number;
    stateOfCharge: number;
    totalInactivitySecs: number;
    extraInactivitySecs?: number;
    totalDurationSecs: number;
    price: number;
    priceUnit: string;
    inactivityStatus: InactivityStatus;
  };
  dateTimestring: string;
  values: Consumption[];
  ocpi?: boolean;
  ocpiWithCdr?: boolean;
  billingData: TransactionBillingData;
  refundData?: TransactionRefundData;
  guestData?: TransactionGuestData;
  ocpiData?: OcpiData;
  offline?: boolean;
}

export interface TransactionGuestData {
  paymentID?: string;
  paymentMethodID?: string;
  holdAmount?: number;
  minimumAmount?: number;
  minimumAmountApplied?: boolean;
  flatFee?: number;
  flatFeeApplied?: boolean;
  paymentCaptured?: boolean;
}

export interface OcpiData {
  session?: OCPISession;
  cdr?: OCPICdr;
  sessionCheckedOn?: Date;
  cdrCheckedOn?: Date;
}

export interface StartTransaction {
  userID: string;
  visualTagID?: string;
  carID?: string;
  departureTime?: Date;
  initialStateOfChargePercent?: number;
  targetStateOfChargePercent?: number;
}

export enum InactivityStatus {
  INFO = 'I',
  WARNING = 'W',
  ERROR = 'E',
}

export enum TransactionStatisticsType {
  REFUND = 'refund',
  HISTORY = 'history',
  ONGOING = 'ongoing',
}

export enum TransactionButtonAction {
  VIEW_TRANSACTION = 'view_transaction',
  EDIT_TRANSACTION = 'edit_transaction',
  CREATE_TRANSACTION = 'create_transaction',
  DELETE_TRANSACTION = 'delete_transaction',
  DELETE_TRANSACTIONS = 'delete_transactions',
  EXPORT_TRANSACTIONS = 'export_transactions',
  EXPORT_PDF_TRANSACTIONS = 'export_pdf_transactions',
  EXPORT_PDF_KMS = 'export_pdf_kms',
  EXPORT_TRANSACTION_OCPI_CDR = 'export_transaction_ocpi_cdr',
  OPEN_REFUND_URL = 'open_refund_url',
  REFUND_TRANSACTIONS = 'refund_transactions',
  REFUND_SYNCHRONIZE = 'refund_synchronize',
  PUSH_TRANSACTION_CDR = 'push_transaction_cdr',
  CREATE_TRANSACTION_INVOICE = 'create_transaction_invoice',
  NAVIGATE_TO_TRANSACTION = 'navigate_to_transaction'
}

export enum ConsumptionUnit {
  AMPERE = 'A',
  KILOWATT = 'kW',
}

export interface StartTransactionDialogData extends TableData {
  chargingStation: ChargingStation;
  connectorID: number;
}

export interface TransactionSmartChargingRuntimeParameters {
  departureTime?: Date; // Date of the departure time - taking into account the timezone of the charging station
  initialStateOfChargePercent?: number;
  targetStateOfChargePercent?: number;
}
